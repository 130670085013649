import { DataFrame } from "../helpers";
import { cn } from "../helpers/common.helper";
import "./styles.scss";

export const Table = (props: {
  data?: DataFrame;
  hightLightLastRow: boolean;
  freezeFirstColumn?: boolean;
  freezeSecondColumn?: boolean;
  sortElements: Array<any>;
  noBoldFooter?:boolean
}) => {
  if (!props.data) return <></>;
  const data = props.data.records;
  const splitIndex = props.hightLightLastRow ? -1 : data.length;
  const format = (r: any) => {
    r = (Math.round(r * 100) / 100).toLocaleString();
    return r;
  };
  const elements = data.slice(0, splitIndex).map((record, index) => {
    return (
      <tr className={`tr-${index}`}>
        {record.map((r, index) => (
          <td
          key={index}
            className={cn(
              `td-${index}`,
              (props.freezeFirstColumn || props.freezeSecondColumn) &&
                index === 0 &&
                "isFreezeFirstColumn",
              props.freezeSecondColumn && index === 1 && " isFreeze2ndColumn"
            )}
          >
            {
              <span className="text-nowrap">
                {Number(r) === r ? format(r) : r ? r : ""}
              </span>
            }
          </td>
        ))}
      </tr>
    );
  });
  const footer = data.slice(splitIndex).map((record) => {
    if(props?.noBoldFooter){

      return <tr>
      {record.map((r, index) => (
        <td
        key={index}
          className={cn(
            `td-${index}`,
            (props.freezeFirstColumn || props.freezeSecondColumn) &&
              index === 0 &&
              "isFreezeFirstColumn",
            props.freezeSecondColumn && index === 1 && " isFreeze2ndColumn"
          )}
        >
          {
            <span className="text-nowrap">
              {Number(r) === r ? format(r) : r ? r : ""}
            </span>
          }
        </td>
      ))}
    </tr>
    }
    return (
      <tr>
        {record.map((r, index) => (
          <th
          key={index}
            className={cn(
              `th-${index}`,
              (props.freezeFirstColumn || props.freezeSecondColumn) &&
                index === 0 &&
                "isFreezeFirstColumn",
              props.freezeSecondColumn && index === 1 && " isFreeze2ndColumn"
            )}
          >
            {
              <span className="text-nowrap">
                {Number(r) === r ? format(r) : r ? r : ""}
              </span>
            }
          </th>
        ))}
      </tr>
    );
  });
  return (
    <div className="table-responsive">
      <table
        className="table table-bordered table-sm th tbale-sm td dataTable"
        id="dataTable"
        width="100%"
        cellSpacing={0}
      >
        <thead>
          <tr>
            {props.data.schema.map((s, index) => {
              const element = props.sortElements[index];
              let direction = element
                ? element.state[element.field]
                : undefined;
              direction =
                direction === ""
                  ? "sorting"
                  : direction === "ASC"
                  ? "sorting sorting_asc"
                  : "sorting sorting_desc";
              return (
                <th
                key={index}
                  className={cn(
                    `th-${index} `,
                    element ? direction : "",
                    (props.freezeFirstColumn || props.freezeSecondColumn) &&
                      index === 0 &&
                      "isFreezeFirstColumn",
                    props.freezeSecondColumn &&
                      index === 1 &&
                      " isFreeze2ndColumn"
                  )}
                  onClick={element ? element.onClick : undefined}
                >
                  <p className={`text-nowrap th-col-${index}`}>{s}</p>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {elements}
          {footer}
        </tbody>
      </table>
    </div>
  );
};
