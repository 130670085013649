import { Dispatch } from "react"
import { df2dfd, dfd2df } from "../../helpers"
import { clientService } from "../../services"
import { ClientActionTypes, LOAD_CLIENTS_PAGING_FAILURE, LOAD_CLIENTS_PAGING_REQUEST, LOAD_CLIENTS_PAGING_SUCCESS, LOAD_CLIENT_DETAIL_FAILURE, LOAD_CLIENT_DETAIL_REQUEST, LOAD_CLIENT_DETAIL_SUCCESS } from "./types"

export const loadClientPagination = (page: number, pageSize: number, brokerName: string | null) => {
    return async (dispatch: Dispatch<ClientActionTypes>) => {
        dispatch({
            type: LOAD_CLIENTS_PAGING_REQUEST,
        })
        await clientService.loadClientPagination(page = page, pageSize = pageSize, brokerName = brokerName).then((res) => {
            // res.data.data = res.data.data.map((r: any)=> (
            //     {
            //         "Tài khoản": r.idClient,
            //         "Tên": r.nameClient,
            //         "Lãi suất": r.interestRate,
            //         "Phí mua": r.costBuy,
            //         "Phí bán": r.costSell,
            //     }
            // ))
            dispatch({
                type: LOAD_CLIENTS_PAGING_SUCCESS,
                payload: res.data
            });
            return {}
        },
            (error) => {
                dispatch({
                    type: LOAD_CLIENTS_PAGING_FAILURE,
                    payload: error.data
                })
                return
            }
        )
        return
    }
}

export const loadClientDetail = (idClient: string) => {
    const extractData = (data: any) => {
        for (var key in data) {
            if (key === "assets") {
                const map = {
                    totalValueLoan: "Tổng tiền vay TB",
                    deposit: "Tiền nộp cọc",
                    realisedPNL: "Lãi lỗ đã thực hiện",
                    expectedPNL: "Lãi lỗ chưa thực hiện",
                    minDeposit: "Cọc phải nộp",
                    nav: "NAV hiện tại",
                    coverageRatio: "Tỷ lệ đảm bảo",
                    remain: "Còn lại",
                    pnl: "Lãi/Lỗ",
                    purchasingPower: "Sức mua",
                } as any
                var dfd = df2dfd(data[key])
                dfd = dfd.generateSeries({
                    coverageRatio: (row) => (Math.round(row.coverageRatio * 10000) / 100).toLocaleString().toString() + "%"
                })
                const roundCols = {} as any
                Object.keys(map).filter((key: any) => {
                    if(!["coverageRatio"].includes(key)){
                        roundCols[key] = (row: any) => Number(row[key]) === row[key] ? Math.round(row[key]) : row[key]
                    }
                })
                dfd = dfd.generateSeries(roundCols)
                dfd = dfd.renameSeries(map)
                data[key] = dfd2df(dfd)
                // const a = dfd2df(dfd)
                // const b =2
            }
            else if (key === "portfolio") {
                const map = {
                    ticker: "Mã chứng khoán",
                    quantity: "Số lượng",
                    quantityAvailable: "Khả dụng",
                    priceBuy: "Giá mua",
                    priceSell: "Giá bán",
                    totalValueBuy: "Tổng tiền mua",
                    totalValueSell: "Tổng tiền bán",
                    pnl: "Lãi/Lỗ",
                    updatedAt: "Ngày/Giờ cập nhật"
                } as any
                var dfd = df2dfd(data[key])
                // dfd = dfd.generateSeries({
                //     updatedAt: (row) => <span className="text-nowrap">{row.updatedAt}</span>
                // })
                const roundCols = {} as any
                Object.keys(map).filter((key: any) => {
                    if(!["ticker", "updatedAt"].includes(key)){
                        roundCols[key] = (row: any) => Number(row[key]) === row[key] ? Math.round(row[key]) : row[key]
                    }
                })
                dfd = dfd.generateSeries(roundCols)
                dfd = dfd.renameSeries(map)
                data[key] = dfd2df(dfd)
            }
            else if (key === "deposite") {
                const map = {
                    date: "Ngày",
                    deposit: "Tiền cọc",
                } as any
                const records = data[key]
                records.schema = records.schema.map((r: any) => (map[r] ? map[r] : r))
            }
            else if (key === "expectedPNL") {
                const map = {
                    dateBuy: "Ngày mua",
                    dateSell: "Ngày bán",
                    ticker: "Mã chứng khoán",
                    quantity: "Số lượng",
                    quantityAvailable: "Khả dụng",
                    priceBuy: "Giá mua",
                    priceSell: "Giá bán",
                    totalValueBuy: "Tổng tiền mua",
                    totalValueSell: "Tổng tiền bán",
                    totalValueLoan: "Tổng tiền vay",
                    costBuy: "Phí mua",
                    costSell: "Phí bán",
                    nDayLoan: "Số ngày vay",
                    nDayAdvance: "Số ngày ứng",
                    costLoanFromDayLoan: "Phí vay từ ngày vay",
                    costLoanFromDayAdvance: "Phí vay từ ngày ứng",
                    costLoan: "Phí vay",
                    pnl: "Lãi/Lỗ",
                    depositRatio: "Tỷ lệ cọc",
                    minDeposit: "Cọc phải nộp",
                } as any
                var dfd = df2dfd(data[key])
                dfd = dfd.generateSeries({
                    depositRatio: (row) => (Math.round(row.depositRatio * 10000) / 100).toLocaleString().toString() + "%"
                })
                const roundCols = {} as any
                Object.keys(map).filter((key: any) => {
                    if(!["dateBuy", "dateSell", "ticker", "depositRatio"].includes(key)){
                        roundCols[key] = (row: any) => Number(row[key]) === row[key] ? Math.round(row[key]) : row[key]
                    }
                })
                dfd = dfd.generateSeries(roundCols)
                dfd = dfd.renameSeries(map)
                data[key] = dfd2df(dfd)
            }
            else if (key === "realisedPNL") {
                const map = {
                    dateBuy: "Ngày mua",
                    dateSell: "Ngày bán",
                    ticker: "Mã chứng khoán",
                    quantity: "Số lượng",
                    priceBuy: "Giá mua",
                    priceSell: "Giá bán",
                    totalValueBuy: "Tổng tiền mua",
                    totalValueSell: "Tổng tiền bán",
                    totalValueLoan: "Tổng tiền vay",
                    costBuy: "Phí mua",
                    costSell: "Phí bán",
                    nDayLoan: "Số ngày vay",
                    nDayAdvance: "Số ngày ứng",
                    costLoanFromDayLoan: "Phí vay từ ngày vay",
                    costLoanFromDayAdvance: "Phí vay từ ngày ứng",
                    costLoan: "Phí vay",
                    pnl: "Lãi/Lỗ",
                } as any
                var dfd = df2dfd(data[key])
                const roundCols = {} as any
                Object.keys(map).filter((key: any) => {
                    if(!["dateBuy", "dateSell", "ticker"].includes(key)){
                        roundCols[key] = (row: any) => Number(row[key]) === row[key] ? Math.round(row[key]) : row[key]
                    }
                })
                dfd = dfd.generateSeries(roundCols)
                dfd = dfd.renameSeries(map)
                data[key] = dfd2df(dfd)
            }
        }
        return data
    }
    return async (dispatch: Dispatch<ClientActionTypes>) => {
        dispatch({
            type: LOAD_CLIENT_DETAIL_REQUEST,
        })
        await clientService.loadClientDetail(idClient).then((res) => {
            res.data.data = extractData(res.data.data)
            dispatch({
                type: LOAD_CLIENT_DETAIL_SUCCESS,
                payload: res.data
            });
            return {}
        },
            (error) => {
                dispatch({
                    type: LOAD_CLIENT_DETAIL_FAILURE,
                    payload: error.data
                })
                return
            }
        )
        return
    }
}
